import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs/";
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Parallax } from "react-scroll-parallax";
const steps = [
  {
    title: "Design",
    desc: "We'll discuss your brief and design your video",
    image: "/images/illustrations/design.svg",
  },
  {
    title: "Data",
    desc: "You send us your spreadsheet",
    image: "/images/illustrations/upload-data.svg",
  },
  {
    title: "Render",
    desc:
      "Our system will power through your data and render each video simultaneously",
    image: "/images/illustrations/render.svg",
  },
  {
    title: "Ready",
    desc:
      "Once complete you will receive your data back with urls to each video",
    image: "/images/illustrations/ready.svg",
  },
  {
    title: "Distribute",
    desc: "Run a campaign or trigger automated events through Zapier",
    image: "/images/illustrations/distribute.svg",
  },
];

const heroData = [
  {
    name: "John Smith",
    location: "St Albans",
    color: "#f67131",
    discount: "15%",
    videoUrl: "/videos/john.mp4",
  },
  {
    name: "Emily Fisher",
    location: "Cheltenham",
    color: "#b1237c",
    discount: "10%",
    videoUrl: "/videos/emily.mp4",
  },
  {
    name: "Aubrey Norris",
    location: "Kensington",
    color: "#90d554",
    discount: "30%",
    videoUrl: "/videos/aubrey.mp4"
  }
];

const codeString = `{
  "templateId": 12345,
  "data": {
    "name": "John Smith",
    "message": "Happy Christmas John! All the best"
  }    
}`;

const autoLogos = [
  {
    path: "/images/autoLogos/fb.svg",
    coords: [140, -140],
    className: "top-52 left-[20%]",
    innerClass: "h-32 w-32",
  },
  {
    path: "/images/autoLogos/twitter.svg",
    coords: [80, -80],
    className: "top-44 right-24",
    innerClass: "h-24 w-24",
  },
  {
    path: "/images/autoLogos/yt.svg",
    coords: [140, -140],
    className: "top-64 right-[20%]",
    innerClass: "h-32 w-32",
  },
  {
    path: "/images/autoLogos/instagram.svg",
    coords: [45, -45],
    className: "top-12 right-[14%]",
    innerClass: "h-28 w-28",
  },
  {
    path: "/images/autoLogos/wp.svg",
    coords: [55, -55],
    className: "bottom-36 right-48",
    innerClass: "h-28 w-28",
  },
  {
    path: "/images/autoLogos/woo.svg",
    coords: [45, -45],
    className: "top-8 left-[13%]",
    innerClass: "h-28 w-28",
  },
  {
    path: "/images/autoLogos/shopify.svg",
    coords: [50, -50],
    className: "bottom-40 left-[6%]",
    innerClass: "h-36 w-36",
  },
];

const Automation = () => {
  const [currentRow, setCurrentRow] = useState(0);
  const [autoplayTableRow, setAutoplayTableRow] = useState(true);

  const [activeStep, setActiveStep] = useState(0);

  if (autoplayTableRow) {
    setTimeout(() => {
      const value = currentRow == heroData.length - 1 ? 0 : currentRow + 1;
      if (autoplayTableRow) {
        setCurrentRow(value);
      }
    }, 10000);
  }

  const tableRowClick = (i) => {
    setAutoplayTableRow(false);
    setCurrentRow(i);
  };

  const getHeroDataContent = (heroData) => {
    let content = [];
    const totalElements = heroData.length * 2 - 2;
    for (
      let currentItemIndex = 0;
      currentItemIndex <= totalElements;
      currentItemIndex++
    ) {
      const relativeIndex = currentItemIndex % heroData.length; // index of card in hero data collection
      const active = currentRow == currentItemIndex ? true : false; // is active row
      const px = (currentItemIndex - currentRow); //
      const ix = px - heroData.length;
      const isInView = currentItemIndex >= currentRow && currentItemIndex < currentRow + heroData.length;
      const data = heroData[relativeIndex];
      content.push(
        <AnimatePresence key={"heroVideos-" + currentItemIndex}>
          <motion.div
            key={"heroVideos-" + currentItemIndex}
            initial="open"
            animate="open"
            variants={{
              open: {
                // opacity: active ? 1 : (ix * -0.25),
                opacity: isInView ? (active ? 1 : ix * -0.25) : 0,
                right: (ix + 1) * -30,
                top: ix * 12,
                zIndex: -1 * ix,
                transition: { ease: "easeOut" },
              },
            }}
            className="absolute h-full"
            style={{ perspective: 1000, perspectiveOrigin: "50% 50%" }}
          >
            <video
              src={data.videoUrl}
              className="rounded-lg shadow-xl"
              width={360}
              autoPlay={true}
              loop={true}
              muted={true}
              style={{
                transform:
                  "scale3d(1, 1, 1) rotateX(11deg) rotateY(-18deg) rotateZ(1deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg)",
              }}
            />
          </motion.div>
        </AnimatePresence>
      );
    }
    // for (let repeatMutex = 0; repeatMutex <= repeat; repeatMutex++) {
    //   heroData.map((data, i) => {
    //     const currentIndex = (repeatMutex * heroData.length) + i;
    //     const active = currentRow == currentIndex ? true : false;
    //     const px = ((currentIndex + totalElements - currentRow) % totalElements);
    //     const ix = px - totalElements;
    //     const isInView = px > heroData.length && px <= heroData.length * 2;
    //     // console.log(currentIndex, px, ix);
    //     content.push(
    //       <AnimatePresence>
    //         <motion.div
    //           key={'heroVideos-' + currentIndex}
    //           initial="open"
    //           animate="open"
    //           variants={{
    //             open: {
    //               // opacity: active ? 1 : (ix * -0.25),
    //               opacity: isInView ? (active ? 1 : (ix * -0.25)) : 0,
    //               right: (ix + 1) * -30,
    //               top: ix * 12,
    //               zIndex: -1 * ix,
    //               transition: { ease: 'easeOut' }
    //             }
    //           }}

    //           className="absolute h-full"
    //           style={{ perspective: 1000, perspectiveOrigin: '50% 50%' }}
    //         >
    //           <video src={data.videoUrl} className="rounded-lg shadow-xl" width={360} autoPlay={true} loop={true} muted={true} style={{ transform: 'scale3d(1, 1, 1) rotateX(11deg) rotateY(-18deg) rotateZ(1deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg)' }} />
    //         </motion.div>
    //       </AnimatePresence>
    //     )
    //   })
    // }

    return content;
  };

  return (
    <Layout transparentHeader={true}>
      <Seo title="Home" />
      <section className="overflow-hidden relative pt-16">
        <div className="container mx-auto px-6 pt-16 pb-16 md:pb-60 lg:pb-60 xl:pb-44">
          <div className="relative z-10 flex flex-wrap -mx-1">
            <div className="w-full px-1 xl:w-2/5">
              <h1 className="h1 text-[2.4rem] sm:text-[3.1rem] 2xl:text-[3.4rem] leading-[1.15em] mb-3 font-extrabold text-gray-800">
                Engage your users with jaw-dropping{" "}
                <span className="text-transparent bg-clip-text bg-gradient-to-tl from-cyan-400 via-blue-600 to-purple-600">
                  personalised media
                </span>
              </h1>
              <h2 className="text-2xl font-primary font-medium text-gray-600 mb-8 pr-16">
                Bulk generate <span className="relative after:absolute after:left-[-3px] after:z-[-1] after:bottom-0 after:w-[calc(100%+6px)] after:h-2 after:bg-yellow-400/40">videos</span> &amp; <span className="relative after:absolute after:left-[-3px] after:z-[-1] after:bottom-0 after:w-[calc(100%+6px)] after:h-2 after:bg-yellow-400/40">images</span> easily with a spreadsheet or via our API
                <img className="inline-flex mb-1 ml-0.5 h-6" src="/images/bolt.png" />
              </h2>
              <a
                href="mailto:hello@editsquare.com"
                className="inline-flex items-center justify-center py-3 px-5 rounded-md text-xl font-semibold text-white bg-gradient-to-tl from-sky-500 via-blue-600 to-indigo-500 hover:from-blue-600 hover:to-blue-600"
              >
                Get in touch now
                <ArrowNarrowRightIcon className="text-white ml-1.5 h-6" />
              </a>
            </div>
            <div className="hidden md:block relative w-full px-1 mt-4 xl:w-3/5">
              <div className="flex flex-col max-w-lg h-full xl:ml-3 mt-4 xl:mt-0">
                <div className="-my-2 h-full">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <div className="border border-gray-200 shadow-sm overflow-hidden border-b rounded-lg">
                      <table className="bg-white min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-800 text-white">
                          <tr>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider"
                            >
                              Location
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider"
                            >
                              Color
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider"
                            >
                              Discount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="relative" style={{ borderTop: 0 }}>
                          {heroData.map((data, i) => (
                            <tr
                              key={"dataTable-" + i}
                              className={`relative transition duration-150 ${i == currentRow
                                ? "text-green-500 bg-transparent"
                                : "cursor-pointer text-gray-700 hover:bg-gray-50 hover:text-gray-800"
                                } `}
                              style={{ zIndex: 1 }}
                              onClick={() => tableRowClick(i)}
                            >
                              <td className="px-4 py-3 whitespace-nowrap text-sm" style={{ height: 2.75 + "rem" }}>
                                {data.name}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm" style={{ height: 2.75 + "rem" }}>
                                {data.location}
                              </td>
                              <td className="flex items-center px-4 py-3 whitespace-nowrap text-sm" style={{ height: 2.75 + "rem" }}>
                                <div className={`h-3 w-3 rounded mr-1.5`} style={{ backgroundColor: data.color }}></div>
                                {data.color}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm" style={{ height: 2.75 + "rem" }}>
                                {data.discount}
                              </td>
                            </tr>
                          ))}
                          <motion.tr
                            key={"highlightRow"}
                            animate={{ top: currentRow * 2.75 + "rem" }}
                            transition={{ type: "linear" }}
                            className="highlightRow absolute w-full bg-green-50"
                            style={{ zIndex: 0 }}
                          >
                            <td colSpan={3} className="px-4 py-3 text-sm min-w-full block" style={{ height: 2.75 + "rem", marginBottom: "1px" }}>&nbsp;</td>
                          </motion.tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {getHeroDataContent(heroData)}

              <div className="absolute hidden xl:block lg:bottom-[-25px] lg:left-[120px] 2xl:bottom-[-10px] 2xl:left-[250px]  rotate-[40deg]">
                <svg
                  className="text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  height={200}
                  width={200}
                  fill="currentColor"
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 100 100"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path d="M14.189,62.771c-0.644-0.256-1.272-0.527-1.886-0.813l0.846-1.813c0.578,0.27,1.171,0.526,1.779,0.768L14.189,62.771z" />
                    </g>
                    <g>
                      <path d="M29.453,65.778c-1.311-0.042-2.619-0.132-3.887-0.269l0.214-1.988c1.219,0.131,2.476,0.218,3.737,0.258L29.453,65.778z     M33.344,65.763l-0.077-1.998c1.233-0.048,2.494-0.139,3.745-0.271l0.21,1.989C35.926,65.62,34.622,65.714,33.344,65.763z     M41.071,64.953l-0.335-1.972c1.223-0.208,2.463-0.458,3.687-0.743l0.453,1.948C43.614,64.48,42.333,64.738,41.071,64.953z     M21.707,64.936c-1.296-0.248-2.576-0.551-3.805-0.903l0.55-1.923c1.171,0.335,2.393,0.625,3.63,0.861L21.707,64.936z     M48.627,63.196l-0.566-1.918c1.195-0.353,2.399-0.746,3.58-1.168l0.673,1.884C51.099,62.428,49.858,62.833,48.627,63.196z     M55.926,60.589l-0.774-1.844c1.147-0.482,2.303-1.004,3.435-1.551l0.871,1.801C58.294,59.558,57.106,60.094,55.926,60.589z     M62.899,57.221l-0.961-1.754c1.09-0.598,2.188-1.235,3.262-1.895l1.047,1.704C65.145,55.954,64.019,56.608,62.899,57.221z     M69.494,53.173l-1.127-1.652c1.027-0.701,2.059-1.44,3.068-2.2l1.202,1.599C71.604,51.697,70.546,52.456,69.494,53.173z     M75.673,48.525L74.4,46.983c0.958-0.792,1.921-1.622,2.861-2.468l1.339,1.486C77.638,46.867,76.654,47.716,75.673,48.525z     M81.415,43.355l-1.399-1.429c0.889-0.871,1.779-1.779,2.646-2.701l1.456,1.371C83.232,41.538,82.323,42.466,81.415,43.355z" />
                    </g>
                    <g>
                      <path d="M86.727,37.712l-1.508-1.313c0.432-0.496,0.86-0.999,1.287-1.51l1.535,1.282C87.605,36.693,87.167,37.207,86.727,37.712z" />
                    </g>
                  </g>
                  <g>
                    <polygon points="87.531,44.544 85.143,44.307 85.896,36.732 78.12,38.475 77.595,36.133 88.613,33.664  " />
                  </g>
                </svg>
              </div>

              {/* 
              <motion.div className="absolute right-0 top-0 h-full" style={{ opacity: 0.2, perspective: 1000, perspectiveOrigin: '50% 50%' }}>
                <video src="https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Template_sample_clips/one_click_preview/production/Fruity__Juicy_production_story_17968_1_1_1608803950794_SD12.mp4" className="rounded-lg shadow-lg" width={360} autoPlay={true} loop={true} muted={true} style={{ transform: 'scale3d(1, 1, 1) rotateX(11deg) rotateY(-18deg) rotateZ(1deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg)' }} />
              </motion.div>

              <div className="absolute right-0 top-0 h-full" style={{ zIndex: 5, opacity: 0.2, perspective: 1000, perspectiveOrigin: '50% 50%' }}>
                <video src="https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Template_sample_clips/one_click_preview/production/Fruity__Juicy_production_story_17968_1_1_1608803950794_SD12.mp4" className="rounded-lg shadow-lg" width={360} autoPlay={true} loop={true} muted={true} style={{ transform: 'scale3d(1, 1, 1) rotateX(11deg) rotateY(-18deg) rotateZ(1deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg)' }} />
              </div>
              <div className="absolute right-0 top-0 h-full" style={{ zIndex: 6, right: 24, top: -25, perspective: 1000, perspectiveOrigin: '50% 50%' }}>
                <video src="https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Template_sample_clips/one_click_preview/production/Fruity__Juicy_production_story_17968_1_1_1608803950794_SD12.mp4" className="rounded-lg shadow-lg" width={360} autoPlay={true} loop={true} muted={true} style={{ transform: 'scale3d(1, 1, 1) rotateX(11deg) rotateY(-18deg) rotateZ(1deg) translate3d(0px, 0px, 0px) skew(0deg, 0deg)' }} />
              </div> */}
            </div>
          </div>
        </div>
        <div className="absolute select-none -top-52 right-0 wobble-down">
          <img
            className="h-[900px]"
            src="/images/blob-2.png"
          />
        </div>
        <div className="absolute select-none -bottom-20 left-0 wobble-up">
          <img
            className="h-[500px]"
            src="/images/blob-1.png"
          />
        </div>
      </section>

      <section>
        <div className="w-full  2xl:max-w-[1600px] mx-auto">
          <div className="bg-gradient-to-l from-sky-50 to-white rounded-2xl">
            <div className="container mx-auto px-6">
              <div className="flex flex-wrap relative z-10 -mx-1">
                <div className="w-full px-1 md:w-3/5">
                  <div className="relative h-[32rem]">
                    <img className="absolute right-[6rem] bottom-[4rem] w-80 rounded-md shadow-2xl" src="/images/xmas.jpg" />
                    <video className="absolute left-0 top-0 lg:top-[-2rem] w-60 rounded-md shadow-2xl" muted={true} loop={true} autoPlay={true} src="/videos/auba.mp4" />
                    <video className="absolute w-48 right-0 top-12 rounded-md sm:rounded-none select-none sm:w-[24%] sm:left-[49.2%] sm:top-[10.3%] md:top-[35.1%] md:left-[42.6%] md:w-[24%] lg:top-[11.8%] lg:left-[48.85%] lg:w-[24%] xl:left-[47.8%] xl:w-[22.4%] xl:top-[-3.5%] 2xl:left-[41.75%] 2xl:top-[-7.75%] 2xl:w-[19.2%] z-10" src="/videos/iPhone.mp4" loop={true} autoPlay={true} muted={true} />
                    <img src="/images/hand.png" className="hidden sm:block absolute select-none left-[-100px] bottom-0 xl:w-[93%] 2xl:w-4/5" />
                  </div>
                </div>
                <div className="w-full px-1 md:w-2/5 py-12">
                  <h3 className="relative font-bold text-2xl text-blue-600 pl-12 mb-1 before:top-3.5 before:left-1 before:absolute before:bg-blue-600 before:h-1 before:w-8">
                    Fast. Very fast.
                  </h3>
                  <h2 className="h2 text-3xl font-bold font-primary mb-10">
                    Render thousands of amazing looking videos in a couple of
                    clicks
                  </h2>
                  <p className="text-lg text-gray-700">Create personalised experiences at scale for your customers. <br />Perfect for data-driven businesses looking to increase engagement through powerful animated media.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-tl from-white via-white to-purple-50/70">
        <div className="container mx-auto px-6 lg:h-[37rem]">
          <div className="flex flex-wrap -mx-1 pt-20 pb-16">
            <div className="w-full lg:w-3/5 px-1">
              {steps.map((step, i) => (
                <AnimatePresence>
                  {i == activeStep && (
                    <motion.div
                      key={"icon-" + i}
                      initial="collapsed"
                      animate="open"
                      variants={{
                        open: {
                          opacity: 1,
                          y: 0,
                          transition: { ease: "easeOut", delay: 0.15 },
                        },
                        collapsed: {
                          opacity: 0,
                          y: 50,
                          transition: { ease: "easeIn" },
                        },
                      }}
                      className="mx-auto max-w-xs lg:max-w-md"
                    >
                      <div className="h-[24rem] lg:h-[32rem]">
                        <img src={step.image} className="w-full mt-8" />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              ))}
            </div>

            <div className="w-full lg:w-2/5 px-1">
              <h3 className="relative font-bold text-2xl text-blue-600 pl-12 mb-4 before:top-3 before:left-1 before:absolute before:bg-blue-600 before:h-1 before:w-8">
                How it works
              </h3>
              <ul className="space-y-2 max-w-2xl">
                {steps.map((step, i) => (
                  <motion.li
                    key={"steps-" + i}
                    className={`relative overflow-hidden rounded-lg py-3 px-3 text-2xl border-2 transition duration-150 ${i == activeStep
                      ? "z-10 text-blue-600 bg-white border-blue-600"
                      : "text-gray-700 bg-gray-100/90 border-transparent cursor-pointer hover:bg-gray-200/60"
                      }`}
                    onClick={() => setActiveStep(i)}
                  >
                    <div className="flex items-center leading-none font-primary">
                      <div className="mr-4">
                        <div
                          className={`inline-flex items-center justify-center font-extrabold h-9 w-9 rounded-full ${i == activeStep ? "text-blue-600" : "text-gray-300"
                            }`}
                        >
                          {i + 1}
                        </div>
                      </div>
                      <h4 className="font-semibold">{step.title}</h4>
                    </div>
                    <AnimatePresence>
                      {i == activeStep && (
                        <motion.div
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 },
                          }}
                          transition={{ ease: "easeOut", delay: 0.15 }}
                          className="ml-[3.25rem]"
                        >
                          <p className="text-lg text-gray-500">{step.desc}</p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="relative overflow-hidden pt-16 pb-28 bg-gradient-to-t from-white to-teal-50/80">
        <div className="max-w-2xl mx-auto">
          <div className="flex items-center w-full justify-center">
            <h3 className="relative text-center font-bold text-2xl pl-12 -ml-12 mb-1 text-teal-600 before:top-3.5 before:left-1 before:absolute before:bg-teal-600 before:h-1 before:w-8">
              Automate
            </h3>
          </div>
          <h2 className="h2 text-center text-gray-700 font-primary font-bold text-3xl">
            Create powerful workflows to automatically render and distribute
            your videos
          </h2>

          <p className="text-sm font-bold tracking-wide text-gray-700 mt-16 mb-4 text-center">
            Example
          </p>
          <div className="w-full flex justify-center">
            <nav aria-label="Progress">
              <ol role="list" className="overflow-hidden">
                <li className="relative pb-10">
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-5 w-0 border border-gray-300/80 border-dashed h-full"
                    aria-hidden="true"
                  />
                  <div className="flex items-center">
                    <img
                      src="/images/autoLogos/gsheets.svg"
                      className="w-10 z-10"
                    />
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-sm font-semibold tracking-wide uppercase text-gray-600">
                        Trigger
                      </span>
                      <span className="text-gray-500">
                        When a new row is added to a Google Spreadsheet
                      </span>
                    </span>
                  </div>
                </li>
                <li className="relative pb-10">
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-5 w-0 border border-gray-300/80 border-dashed h-full"
                    aria-hidden="true"
                  />
                  <div className="flex items-center">
                    <img src="/images/logo-sm.png" className="w-10 z-10" />
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-sm font-semibold tracking-wide uppercase text-gray-600">
                        Action
                      </span>
                      <span className="text-gray-500">Render a new video on Edit Square</span>
                    </span>
                  </div>
                </li>
                <li className="relative">
                  <div className="flex items-center">
                    <img
                      src="/images/autoLogos/mailchimp.svg"
                      className="w-10 z-10"
                    />
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-sm font-semibold tracking-wide uppercase text-gray-600">
                        Action
                      </span>
                      <span className="text-gray-500">
                        Send an email campaign through Mail Chimp
                      </span>
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="text-center mt-16">
            <a
              href="mailto:hello@editsquare.com"
              className="inline-flex py-3 px-5 cursor-pointer rounded-md font-medium bg-white text-teal-500 border-2 border-teal-500 transition duration-150 hover:bg-teal-500 hover:text-white">
              Get in touch
            </a>
          </div>
        </div>

        {autoLogos.map((logo, i) => (
          <Parallax
            key={"logos-" + i}
            y={logo.coords}
            className={`hidden xl:block absolute ${logo.className}`}
          >
            <div
              className={`flex items-center justify-center p-6 rounded-full bg-white shadow-xl ${logo.innerClass}`}
            >
              <img src={logo.path} className="w-full" />
            </div>
          </Parallax>
        ))}
      </div>

      <section className="relative bg-gradient-to-bl from-coolGray-900 via-gray-900 to-black py-16">
        <div className="relative z-20">
          <div className="flex items-center w-full justify-center">
            <h3 className="relative text-center font-bold text-2xl text-sky-400 pl-12 -ml-12 mb-1 before:top-3.5 before:left-1 before:absolute before:bg-sky-400 before:h-1 before:w-8">
              Developer Friendly
            </h3>
          </div>
          <h2 className="h2 text-center text-white font-primary font-bold text-3xl">
            Optional API{" "}
            <span className="text-sky-200 opacity-[0.55]">
              for advanced workflows
            </span>
          </h2>
          <div className="container mx-auto px-6 my-12 w-full max-w-3xl rounded-lg shadow-2xl">
            <div className="flex flex-row items-center h-8 px-2 bg-gray-900 rounded-t-md border border-gray-700/60">
              <div className="h-3 w-3 rounded-full border border-red-500 mr-1.5"></div>
              <div className="h-3 w-3 rounded-full border border-orange-500 mr-1.5"></div>
              <div className="h-3 w-3 rounded-full border border-grass-500"></div>
            </div>
            <SyntaxHighlighter
              language="json"
              style={vs2015}
              showLineNumbers={true}
              customStyle={{
                borderRadius: "0 0 0.375rem 0.375rem",
                backgroundColor: "#18181B",
              }}
            >
              {codeString}
            </SyntaxHighlighter>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 h-full w-full opacity-[0.15] bg-repeat"
          style={{
            backgroundSize: 65,
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.41'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          }}
        ></div>
      </section>
    </Layout>
  );
};

export default Automation;
